import request from "@/utils/request";
import qs from "qs";
export const postVisitInfoAddApi = params => {
  return request({
    method: "post",
    url: "/visitInfo/add",
    data: qs.stringify(params)
  });
};

export const getVisitApi = params => {
  return request({
    url: "/vfTableInfo/info",
    method: "get",
    params
  });
};

// 主要体征
export const postSignAddApi = params => {
  return request({
    method: "post",
    url: "/mbglPatienceSign/add",
    data: qs.stringify(params)
  });
};//添加

export const getSignListApi = params => {
  return request({
    url: "/mbglPatienceSign/list",
    method: "get",
    params
  });
};//数据图表，

// 基本信息/编辑
export const postPatienceEditApi = params => {
  return request({
    method: "post",
    url: "/patience/update",
    data: qs.stringify(params)
  });
};

